.swiper-pagination-bullet {
    width: 426px;
    background-color: white;
    border-radius: 0% !important;
    height: 2px !important;

} 


@media screen and (max-width: 665px) {
    .swiper-pagination-bullet {
        width: 30%;
        background-color: white;
        border-radius: 0% !important;
        height: 2px !important;
    
    } 
  }